<template>
  <div>
    <div v-show="!isModify">
      <!-- 下载模块 -->
      <div
        style="
          border: 1px, solid, rgb(221, 221, 221);
          height: auto;
          background-color: rgb(245, 250, 254);
          margin-bottom: 10px;
        "
      >
        <div>
          <!-- 下载栏 -->
          <div class="xzl">
            <div class="xzl-xzmb" @click="downloadTemplate">下载模版</div>
            <div class="xzl-div-btn">
              <el-button class="xzl-div-btns" @click="tjfj">
                <i style="font-size: 20px" class="el-icon-upload"></i>上传excel
              </el-button>
              <!-- 上传excel -->
              <el-dialog
                title="上传excel"
                :visible.sync="tjfjVisble"
                :close-on-click-modal="false"
              >
                <div style="display: flex; justify-content: center">
                  <el-upload
                    style="width: auto"
                    class="centerImg"
                    :file-list="fileList"
                    ref="uploadicon"
                    :action="url"
                    :on-remove="handleRemove"
                    :auto-upload="true"
                    :before-upload="handleBeforeUpload"
                    :on-change="handleAvatarChangeIcon"
                    accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    multiple
                    drag
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或<em>点击上传</em>
                    </div>
                    <div class="el-upload__tip" slot="tip">
                      只能上传xlsx文件，且不超过1MB
                    </div>
                  </el-upload>
                </div>
    
              </el-dialog>
            </div>
          </div>
          <!-- 查询栏 -->
          <div class="cxl">
            <el-form
              :inline="true"
              :model="formLabelAlign"
              class="demo-form-inline"
            >
            <el-form-item label="状态" class="custom-form-item">
                  <el-select
            v-model="formLabelAlign.status"
            placeholder="状态"
            class="cxl-input"
          >
            <el-option label="已经删除" value="1"></el-option>
            <el-option label="待审核" value="2"></el-option>
            <el-option label="导入失败" value="3"></el-option>
            <el-option label="OA审批通过" value="4"></el-option>
            <el-option label="OA审核不通过" value="5"></el-option>
          </el-select>
              </el-form-item>
              <!-- <el-form-item label="车牌号码" class="custom-form-item">

                <el-input
                  v-model="formLabelAlign.vehiclePlateNumber"
                  placeholder="请输入车牌号码"
                  class="cxl-input"
                ></el-input>
              </el-form-item>
              <el-form-item label="投保人" class="custom-form-item">
                <el-input
                  v-model="formLabelAlign.applicantName"
                  placeholder="请输入投保人"
                  class="cxl-input"
                ></el-input>
              </el-form-item>
              <el-form-item label="被保险人" class="custom-form-item">
                <el-input
                  v-model="formLabelAlign.insuredName"
                  placeholder="请输入被保险人"
                  class="cxl-input"
                ></el-input>
              </el-form-item> -->
           <el-form-item v-for="(item) in tableQueryForm" :key="item.fieId" :label="item.fieldName">
          <el-input   class="cxl-input"  :value="formLabelAlign[item.field]"  @input="updateformLabelAlign(item.field, $event)" >
          </el-input>
        </el-form-item>
              <!--                   @change="handleStartDateChange" -->
              <el-form-item label="起保日期" class="custom-form-item">
                <el-date-picker
                  v-model="formLabelAlign.startDate"
                  type="date"
                  placeholder="选择起保日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="终保日期" class="custom-form-item">
                <el-date-picker
                  v-model="formLabelAlign.endDate"
                  type="date"
                  placeholder="选择终保日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  :picker-options="endDatePickerOptions"
                >
                </el-date-picker>
              </el-form-item>

              <el-form-item class="cxl-form-btns">
                <el-button class="cxl-form-btn" @click="onSubmit"
                  >查询</el-button
                >
                <el-button
                  class="cxl-form-btn"
                  style="background-color: rgba(231, 102, 109)"
                  @click="exportToExcel"
                  >导出</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <!-- 金额显示栏 -->
          <!-- <div
            style="
              height: 35px;
              line-height: 35px;
              font-size: 12px;
              font-weight: 900;
              padding-left: 25px;
            "
          >
            总申报数量 {{ this.Usersbxx.zsbsl }}；保费合计：人民币{{
              this.Usersbxx.bfhj
            }}元；手续费合计{{ this.Usersbxx.sxfhj }}元
          </div> -->
        </div>
      </div>
      <!-- table模块  ref表格数据 //querytable重新请求-->
      <div>
        <xt-table
          ref="tableComponent"
          :tableTop="tableTop"
          :tableData="tableData"
          :declCount="declCount"
          :insType="formLabelAlign.insType"
          :pageType='formLabelAlign.pageType'
          @isModify="openLp"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
          @rowData="getNowData"
          @querytable="getTableData" 
        ></xt-table>
      </div>
    </div>
    <!-- 理赔 -->
    <div v-show="isModify">
      <h4>编辑信息</h4>
      <!-- :model="NowData"  -->
      <el-form ref="form" label-width="80px">
        <el-form-item v-for="(item,index) in tableTop" :key="item.fieId" :label="item.fieldName">
          <el-select  
        v-if="item.field === 'status'"  
        v-model="NowData[item.field]"  
        placeholder="请选择状态"  
        style="width: 30vw"  
      >  
            <el-option label="待审核" value="2"></el-option>
            <el-option label="OA审批通过" value="4"></el-option>
      </el-select>  
          <el-input v-else :value="NowData[item.field]" :disabled="item.field === 'id' || item.field === 'createdAt'" @input="updateNowData(item.field, $event)"     style="width: 30vw">
          </el-input>
        </el-form-item>
    
        <el-form-item>
          <el-button type="primary" @click="tjBj">确认提交</el-button>
          <el-button @click="qxbj">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
// 引入table top 组件
import xtTableTop from "@/components/Public/Tabletop/index.vue";
// 引入table组件
import xtTable from "@/components/Public/Table/index.vue";
import {download} from "@/axios/download.js"; //导出类型
import axios from 'axios';  
import { getDataTop,upLoadlpData ,formDataBj,getQueryForm } from "@/axios/tableTop"; //获取table表格头// 统一更新table数据
// 引入syx.JS
import { lpglClgzzrxData } from "@/axios/Sbpt/Sbpt_sbgl/index.js";
export default {
  components: {
    xtTable,
    xtTableTop,
  },
  data() {
    return {
      templateId: "1", // 这个就是页面的ref 用来判定需要哪一个模版
      tableTop: [
      ], // 表格头
      tableQueryForm:[], //不同险种的不同动态查询条件
      tableData: [], //表格数据
      // 当前业务类型

      Usersbxx: {
        //总申报数量信息
        zsbsl: "",
        bfhj: "",
        sxfhj: "",
      }, // 表格申报信息展示数据 从table总获取
      labelPosition: "right",
      formLabelAlign: {
        current: 1,
        size: 50,
        pageType:'',
        insType: "",
        startDate: "",
        endDate: "",
        // applicantName: "", //投保人姓名
        // insuredName: "", //被保险人姓名
        // vehiclePlateNumber: "", //车牌号码
        status:'', //确定是申报入口
        fileName:'',//下载模版
      },
// formOnesZT:'',
      isLp: false, //true是理赔 false不是理赔
      isModify: false, //true是编辑 false不是编辑
      fileList: [], // 用于 理赔存储上传的文件列表
      iconformData: [{ url: "", name: "" }],
      tjfjVisble: false, //添加附件
      declCount: 0, //总条数
      NowData:{}, //编辑数据
      username:JSON.parse(localStorage.getItem("username")),
      queryDownFile:'',//下载地址
      url:window.ipConfigUrl.baseURL1+'import/decl/vehicleEmpLiabilityIns/InsPriceData'
    };
  },
  computed: {
    // 验证日期选择 起保日期在结束日期之前
    endDatePickerOptions() {
      return {
        disabledDate: (time) => {
          if (!this.formLabelAlign.startDate) {
            return false;
          }
          const startDate = new Date(this.formLabelAlign.startDate);
          return time.getTime() < startDate.getTime();
        },
      };
    },
  },
  methods: {
    updateformLabelAlign(field, value) {  
      this.$set(this.formLabelAlign, field, value); // 使用$set确保响应性  
    },  
    updateNowData(field, value) {  
      this.$set(this.NowData, field, value); // 使用$set确保响应性  
    },  
    // 子组件传参 理赔
    openLp(message,id) {
      
      this.isModify = message; //打开编辑
      // 请求详情页接口
      formDataBj({id:id})
      .then((res) => {
        
      });
    },
    // 取消 取消理赔
    resetForm() {
      this.isModify = false;
    },
    //文件列表移除文件时的钩子
    handleRemove() {
      this.iconformData = [];
    },
    onSubmit() {
      // 查询发送请求参数给父组件
      // 初始化请求table数据
      this.allData(this.formLabelAlign);
    },
    // 页数变动
    handleSizeChange(e) {

      this.formLabelAlign.size = e;
      this.allData(this.formLabelAlign);
    },
    // 页码变动
    handleCurrentChange(e) {
      this.formLabelAlign.current = e;
      this.allData(this.formLabelAlign);
    },
    // 下载xlsx 模版
    downloadTemplate() {
      if (this.formLabelAlign.insType=='04') {
              this.fileName = "理赔模版-货运险.xlsx";
      }else{
     // 根据templateId或其他props生成Excel数据
              this.fileName = "理赔模版-通用模版.xlsx";
      }
 

                download({fileName: this.fileName})
                    .then(res => {
                      let blob = new Blob([res], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                      let url = window.URL.createObjectURL(blob);
                      let link = document.createElement('a');
                      link.href = url;
                      if (this.formLabelAlign.insType=='01') {
                        link.download = '商业险.xlsx';
                      }
                      if (this.formLabelAlign.insType=='02') {
                        link.download = '车辆雇主责任险.xlsx';
                      }
                      if (this.formLabelAlign.insType=='03') {
                        link.download = '交强险.xlsx';
                      }
                      if (this.formLabelAlign.insType=='04') {
                        link.download = '货运险.xlsx';
                      }
                      if (this.formLabelAlign.insType=='05') {
                        link.download = '财产险.xlsx';
                      }
                      if (this.formLabelAlign.insType=='06') {
                        link.download = '员工雇主责任险.xlsx';
                      }
                      if (this.formLabelAlign.insType=='07') {
                        link.download = '货运三者险.xlsx';
                      }
                      if (this.formLabelAlign.insType=='08') {
                        link.download = '商品车运输险.xlsx';
                      }
                      link.click();
                      window.URL.revokeObjectURL(url);
                    })
    },
    // 点击 上传附件
    tjfj() {
      this.fileList = [];
      this.tjfjVisble = true;
    },
        // 获取编辑参数
        getNowData(message) {
      this.NowData = message;
    },
    // 提交编辑
    tjBj() {
      
      upLoadlpData (this.NowData).then((res) => {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.isModify = false;
        // 刷新table表格
        this.allData(this.formLabelAlign);
      });
    },
                // 编辑 取消
                qxbj() {
                  this.isModify = false; //退出编辑页
            },
    //文件列表移除文件时的钩子
    handleRemove() {
      this.iconformData = [];
    },
    handleBeforeUpload(file) {  
  // 在这里不阻止上传，而是触发自定义的上传逻辑  
  if (this.formLabelAlign.insType=='01') {
                        this.queryDownFile=window.ipConfigUrl.baseURL1+'import/claims/propertyIns/insPriceData'
                      }
                      if (this.formLabelAlign.insType=='02') {
                         this.queryDownFile=window.ipConfigUrl.baseURL1+'claims/vehicleEmpLiabilityIns/insPriceData'
                      }
                      if (this.formLabelAlign.insType=='03') {
                        this.queryDownFile=window.ipConfigUrl.baseURL1+'claims/compulsoryTrafficIns/insPriceData'
                      }
                      if (this.formLabelAlign.insType=='04') {
                        this.queryDownFile=window.ipConfigUrl.baseURL1+'claims/cargoIns/insPriceData'
                      }
                      if (this.formLabelAlign.insType=='05') {
                        this.queryDownFile=window.ipConfigUrl.baseURL1+'claims/commercialIns/insPriceData'
                      }
                      if (this.formLabelAlign.insType=='06') {
                        this.queryDownFile=window.ipConfigUrl.baseURL1+'claims/empLiabilityIns/insPriceData'
                        
                      }
                      if (this.formLabelAlign.insType=='07') {
                        this.queryDownFile=window.ipConfigUrl.baseURL1+'claims/cargo3LiabilityIns/insPriceData'
                      }
                      if (this.formLabelAlign.insType=='08') {
                        this.queryDownFile=window.ipConfigUrl.baseURL1+'import/claims/vehicleTransportationIns/InsPriceData'
                      }

      this.customUpload(file,this.queryDownFile);  
      // 返回false以阻止Element UI的默认上传行为  
      return false;  
    },  
    // 操作添加附件【上传】按钮
    handleAvatarChangeIcon(file, fileList) {
      const isXLSX =
        file.raw.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isXLS = file.raw.name.endsWith(".xls");
      const isCorrectFile = isXLSX || isXLS;
      const isLt2M = file.raw.size / 1024 / 1024 < 1;
      if (!isCorrectFile) {
        this.$message.error("上传excel上传xlsx/xls格式文件!");
        return false;
      } else if (!isLt2M) {
        this.$message.error("上传excel大小不能超过 1MB!");
        return false;
      } else if (isLt2M && isCorrectFile) {


      }
    },
    customUpload(file,downFile) {  
      // 自定义上传逻辑，可以添加username到请求体中  
      const formData = new FormData();  
      formData.append('file', file); // 假设file.raw是文件对象，具体取决于你的实际情况  
      formData.append('username', this.username);  
  
      // 使用axios或其他HTTP客户端发送请求  
      axios.post(downFile, formData, {  
        headers: {  
          'Content-Type': 'multipart/form-data',  
        },  
      })  
      .then(response => {  
        if (response.data.code==200) {
          this.$message({
            type:'success',
            message:response.data.message,
          });
                              // 重新获得表格数据
        this.allData(this.formLabelAlign);   
        }else{
          this.$message({
            type:'error',
            message:'提交错误',
          });
        }
        this.tjfjVisble = false;
      })  
      .catch(error => {  
        // 处理上传失败后的逻辑  
        console.error(error);  
      });  
    }, 
    // 导出excel
    exportToExcel() {
      if (this.formLabelAlign.insType=='01') {
        let link = document.createElement('a');
  link.href = window.ipConfigUrl.baseURL1+'export/claims/propertyInsClaimsData';
  link.click();
      }
      if (this.formLabelAlign.insType=='02') {
        let link = document.createElement('a');
  link.href = window.ipConfigUrl.baseURL1+'export/claims/vehicleEmpLiabilityInsClaimsData';
  link.click();
}
if (this.formLabelAlign.insType=='03') {
  let link = document.createElement('a');
  link.href = window.ipConfigUrl.baseURL1+'export/claims/compulsoryTrafficInsClaimsData';
  link.click();
}
if (this.formLabelAlign.insType=='04') {
  let link = document.createElement('a');
  link.href = window.ipConfigUrl.baseURL1+'export/claims/cargoInsClaimsData';
  link.click();
}
if (this.formLabelAlign.insType=='05') {
  let link = document.createElement('a');
  link.href = window.ipConfigUrl.baseURL1+'export/claims/commercialInsClaimsData';
  link.click();
}
if (this.formLabelAlign.insType=='06') {
  let link = document.createElement('a');
  link.href = window.ipConfigUrl.baseURL1+'export/claims/empLiabilityInsClaimsData';
  link.click();
}
if (this.formLabelAlign.insType=='07') {
  let link = document.createElement('a');
  link.href = window.ipConfigUrl.baseURL1+'export/claims/cargo3LiabilityInsData';
  link.click();
}
if (this.formLabelAlign.insType=='08') {
  let link = document.createElement('a');
  link.href = window.ipConfigUrl.baseURL1+'export/claims/vehicleTransportationInsClaimsData';
  link.click();
}
    },
    // 获取表单所有数据
    allData(e) {
      lpglClgzzrxData(e).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records;
          // 获取总数和保费信息
          this.declCount = res.data.total; //总条数
          this.$message.success("获取表格数据成功");
        }
      });
    },
    // 删除成功从新请求数据
    getTableData(e){
if (e==true) {
  this.allData(this.formLabelAlign);
}
    },
    // 获取当前查询form
    queryForm(){
      getQueryForm({affairType:'1',insType:this.formLabelAlign.insType})
      .then(res=>{
        this.tableQueryForm=res.data
        
      })
    }

  },
  mounted() {
    let nowYw= JSON.parse(localStorage.getItem("ListName"));
    let nowYwChild= JSON.parse(localStorage.getItem("insType"));
    // 获取当前是申报入口还是台账管理
    if (nowYwChild=='申报入口') {
      this.formLabelAlign.pageType = '1';
      // this.formLabelAlign.status=this.formOnesZT
    }else{
      this.formLabelAlign.pageType = '1';
      this.formLabelAlign.status='4'
    }
    // 获取默认选中的险种
    if (nowYw=='员工雇主责任险') {
      this.formLabelAlign.insType = '06';
    } if(nowYw=='车辆雇主责任险'){
      this.formLabelAlign.insType = '02';
    } if(nowYw=='货运三者险'){
      this.formLabelAlign.insType = '07';
} if(nowYw=='商业险'){
  this.formLabelAlign.insType = '01';
} if(nowYw=='交强险'){
  this.formLabelAlign.insType = '03';
} if(nowYw=='财产险'){
  this.formLabelAlign.insType = '05';
} if(nowYw=='货运险'){
  this.formLabelAlign.insType = '04';
}if(nowYw=='商品车运输险'){
  this.formLabelAlign.insType = '08';
}
    
    // 
    // 初始化请求table数据
    this.allData(this.formLabelAlign);
    // 初始化form 查询参数
    this.queryForm();
    // 初始化请求tabletop数据
    getDataTop({pageType:this.formLabelAlign.pageType,insType:this.formLabelAlign.insType}).then((res) => {
      this.tableTop = res.data;
    });
  },
};
</script>
<style lang="less" scoped>
// 下载栏
.xzl {
  border: 1px, solid, rgb(221, 221, 221);
  display: flex;

  // 下载模版
  .xzl-xzmb {
    font-size: 12px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(30, 159, 255);
    font-weight: 900;
    cursor: pointer;
    margin-right: 15px;
  }

  // 上传+导入
  .xzl-div-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    // 按钮公共样式
    .xzl-div-btns {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .xzl-div-btns:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}

// 查询栏
.cxl {
  border: 1px solid rgb(221, 221, 221);
  position: relative;
  padding: 10px;
  padding-bottom: 0px;

  // input输入框 label
  /deep/ .custom-form-item .el-form-item__label {
    font-size: 12px !important;
    /* 修改字体大小 */
    width: 72px;
    /* 限制宽度 */
    overflow: hidden;
    /* 如果需要，可以添加溢出隐藏 */
    text-overflow: ellipsis;
    /* 文本溢出时显示省略号 */
    white-space: nowrap;
    /* 保持文本在一行内 */
  }

  // input输入框
  .cxl-input {
    width: 180px;
    font-size: 12px;
    height: 30px !important;
    /* 使用 !important 确保覆盖默认样式 */
  }

  // 修改input高度
  /deep/ .el-input__inner {
    height: 26px !important;
  }

  // 修改el-form-item行之间间距
  .el-form-item {
    margin-bottom: 10px !important;
  }

  // 查询项 按钮栏
  .cxl-form-btns {
    position: absolute;
    bottom: 0px;
    right: 30px;

    // 按钮
    .cxl-form-btn {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .cxl-form-btn:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}
</style>
