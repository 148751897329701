import Vue from 'vue'
import VueRouter from 'vue-router'
// 登录页
import LoginPage  from '@/views/Login/index.vue'
// 首页
import HomeView from '@/views/Home/homePage.vue'
// table 首页
import Home from '@/views/Home/index.vue'
// @ 申报管理
 //员工雇主责任险 代表所有申报管理险种页面
import sbYggzzrx_sbrk from '@/views/Sbpt/sbgl/yggzzrx/sbrk.vue'
// import sbYggzzrx_tzgl from '@/views/Sbpt/sbgl/yggzzrx/tzgl.vue'
// //车辆雇主责任险
// import sbClgzzrx_sbrk from '@/views/Sbpt/sbgl/clgzzrx/sbrk.vue'
// import sbClgzzrx_tzgl from '@/views/Sbpt/sbgl/clgzzrx/tzgl.vue'
//  //货运三者险
// import sbHyszx_sbrk from '@/views/Sbpt/sbgl/hyszx/sbrk.vue'
// import sbHyszx_tzgl from '@/views/Sbpt/sbgl/hyszx/tzgl.vue'
// import sbSbrk from '@/views/Sbpt/sbgl/hyx/sbrk.vue' // 货运险  -申报入口
// import sbTzgl from '@/views/Sbpt/sbgl/hyx/tzgl.vue' // 货运险  -台账管理
//  //商业险
// import sbSyx_sbrk from '@/views/Sbpt/sbgl/syx/sbrk.vue'
// import sbSyx_tzgl from '@/views/Sbpt/sbgl/syx/tzgl.vue'
// //交强险
// import sbJqx_sbrk from '@/views/Sbpt/sbgl/jqx/sbrk.vue'
// import sbJqx_tzgl from '@/views/Sbpt/sbgl/jqx/tzgl.vue'
// //财产险
// import sbCcx_sbrk from '@/views/Sbpt/sbgl/ccx/sbrk.vue'
// import sbCcx_tzgl from '@/views/Sbpt/sbgl/ccx/tzgl.vue'
// @理赔管理
 //员工雇主责任险 代表所有申报管理险种页面
import lpYggzzrx_sbrk from '@/views/Sbpt/lpgl/yggzzrx/sbrk.vue'
// import lpYggzzrx_tzgl from '@/views/Sbpt/lpgl/yggzzrx/tzgl.vue'
// //车辆雇主责任险
// import lpClgzzrx_sbrk from '@/views/Sbpt/lpgl/clgzzrx/sbrk.vue'
// import lpClgzzrx_tzgl from '@/views/Sbpt/lpgl/clgzzrx/tzgl.vue'
// //货运三者险
// import lpHyszx_sbrk from '@/views/Sbpt/lpgl/hyszx/sbrk.vue'
// import lpHyszx_tzgl from '@/views/Sbpt/lpgl/hyszx/tzgl.vue'
// import lpSbrk from '@/views/Sbpt/lpgl/hyx/sbrk.vue' // 货运险  -申报入口
// import lpTzgl from '@/views/Sbpt/lpgl/hyx/tzgl.vue' // 货运险  -台账管理
// //商业险
// import lpSyx_sbrk from '@/views/Sbpt/lpgl/syx/sbrk.vue'
// import lpSyx_tzgl from '@/views/Sbpt/lpgl/syx/tzgl.vue'
// //交强险
// import lpJqx_sbrk from '@/views/Sbpt/lpgl/jqx/sbrk.vue'
// import lpJqx_tzgl from '@/views/Sbpt/lpgl/jqx/tzgl.vue'
// //财产险
// import lpCcx_sbrk from '@/views/Sbpt/lpgl/ccx/sbrk.vue'
// import lpCcx_tzgl from '@/views/Sbpt/lpgl/ccx/tzgl.vue'
// 运输管理
import Ysgl from '@/views/Sbpt/ysgl.vue'
// 整体分析
import Ztfx from '@/views/Sbpt/ysgl.vue'
// 预存管理
import Ycgl from '@/views/Sbpt/ycgl.vue'
// oa
import Oa from '@/views/Sbpt/oa.vue'
// 驾驶舱
import Jsc from '@/views/Sbpt/jsc.vue'
// @系统设置
import Sxfsz from '@/views/Xtsz/Xtsz/sxfsz.vue' //手续费设置
import newCar from '@/views/Xtsz/Xtsz/hyxbfsz/newCar.vue' //货运险新车保费设置
import secoundCar from '@/views/Xtsz/Xtsz/hyxbfsz/secoundCar.vue' //货运险二手车保费设置
import Spcbfsz from '@/views/Xtsz/Xtsz/spcbfsz.vue' //商品车保费设置
import escsxfss from '@/views/Xtsz/Xtsz/escsxfss.vue'// 二手车手续费设置
import Hyxtjss from '@/views/Xtsz/Xtsz/hyxtjss.vue' //货运险条件设置
// @用户管理
import Jsgl from '@/views/Xtsz/Yhgl/jsgl.vue' //角色管理
import Khgl from '@/views/Xtsz/Yhgl/khgl.vue'// 客户管理
import Yhgl from '@/views/Xtsz/Yhgl/yhgl.vue' //用户管理
import Czrz from '@/views/Xtsz/Yhgl/czrz.vue' //操作日志
Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/login'
},
    {
        path: '/login',
        component: LoginPage
    },
  {
    path: '/HomeView',
    name: 'HomeView',
    component: HomeView,
    redirect: '/decl/show/ins/formdata',
    children:[
      {path: '/Home', component: Home},
      // @申报管理
      {path: '/decl/show/ins/formdata', component: sbYggzzrx_sbrk}, 
      {path: '/decl/show/ins/formdata/tzgl', component: sbYggzzrx_sbrk}, 
      // {path: '/decl/vemp/ins/formdata', component: sbClgzzrx_sbrk}, 
      // {path: '/decl/vemp/ins/formdata/tzgl', component: sbClgzzrx_tzgl},  //车辆雇主责任险
      // {path: '/decl/cargo3/ins/formdata', component: sbHyszx_sbrk},
      // {path: '/decl/cargo3/ins/formdata/tzgl', component: sbHyszx_tzgl},
      // {path: '/decl/biz/ins/formdata', component: sbSyx_sbrk}, 
      // {path: '/decl/biz/ins/formdata/tzgl', component:sbSyx_tzgl},

      // {path: '/decl/comp/ins/formdata', component: sbJqx_sbrk}, 
      // {path: '/decl/comp/ins/formdata/tzgl', component: sbJqx_tzgl},
      // {path: '/decl/prop/ins/formdata', component: sbCcx_sbrk},
      //  {path: '/decl/prop/ins/formdata/tzgl', component: sbCcx_tzgl}, 
      //  {path: '/decl/cargo/ins/formdata', component: sbSbrk},
      // {path: '/decl/cargo/ins/formdata/tzgl', component: sbTzgl},


      // @理赔管理
       //员工雇主责任险
      {path: '/claims/show/ins/formdata', component: lpYggzzrx_sbrk}, 
      {path: '/claims/show/ins/formdata/tzgl', component: lpYggzzrx_sbrk}, 
    //   {path: '/claims/vemp/ins/formdata', component: lpClgzzrx_sbrk}, 
    //   {path: '/claims/vemp/ins/formdata/tzgl', component: lpClgzzrx_tzgl}, 
    //   {path: '/claims/cargo3/ins/formdata', component: lpHyszx_sbrk},
    //   {path: '/claims/cargo3/ins/formdata/tzgl', component: lpHyszx_tzgl},
    //  {path: '/claims/biz/ins/formdata', component: lpSyx_sbrk}, 
    //  {path: '/claims/biz/ins/formdata/tzgl', component:lpSyx_tzgl}, 
    //   {path: '/claims/comp/ins/formdata', component: lpJqx_sbrk}, 
    //   {path: '/claims/comp/ins/formdata/tzgl', component: lpJqx_tzgl},
    //   {path: '/claims/prop/ins/formdata', component: lpCcx_sbrk},
    //    {path: '/claims/prop/ins/formdata/tzgl', component: lpCcx_tzgl}, 
    //    {path: '/claims/cargo/ins/formdata', component: lpSbrk},
    //   {path: '/claims/cargo/ins/formdata/tzgl', component: lpTzgl},

      // {path: '/cs/insurance/employer-liability', component: lpYggzzrx}, 
      // {path: '/cs/insurance/vehicle-employer-liability', component: lpClgzzrx}, 
      // {path: '/cs/insurance/cargo-third-party', component: lpHyszx},
      // {path: '/lp/declaration-entry', component: lpSbrk},
      // {path: '/lp/record-management', component: lpTzgl},
      // {path: '/cs/insurance/commercial', component: lpSyx}, 
      // {path: '/cs/insurance/compulsory', component: lpJqx}, 
      // {path: '/cs/insurance/property', component: lpCcx},
      // 运输管理
      {path: '/Ysgl', component: Ysgl},
      // 整体分析
      {path: '/tms/analyse', component: Ztfx},
        // 预存管理
      {path: '/tms/presave', component: Ycgl},
        // oa
      {path: '/Oa', component: Oa},
        // 驾驶舱
      {path: '/tms/cockpit', component: Jsc},
      // @系统管理
// @货运险保费设置
      {path: '/system/setting/hyxbf', component: newCar},
      {path: '/system/setting/usedcars', component: secoundCar},
      // 货运险条件设置
      {path: '/system/setting/freight/formdata', component: Hyxtjss},
      {path: '/system/setting/sxf', component: Sxfsz},
      {path: '/system/setting/spcbf', component: Spcbfsz},
      {path: '/system/setting/rscsxf', component: escsxfss},
      {path: '/tms/all/user', component: Yhgl},
      // @用户管理
      {path: '/tms/all/role', component: Jsgl},
      // 客户管理
      {path: '/tms/all/customer', component: Khgl},
      {path: '/admin/logs', component: Czrz},
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
